import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: '#417aab',
        secondary: '#424242',
        accent: '#44acae',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        gray: '#828282',
        headerMenuBackgroundColor: '#F0F0F0',
        bluePrimary: '#2F80ED',
        redPrimary: '#BB452C',
        grayPrimary: '#EFEFEF',
        greenPrimary: '#189F51',
        orangePrimary: '#DCB02A',
        orangeSecondary: '#FF3306',
        darkBlue: '#303644',
        lightBlue: '#aac8f2',
        lightGreen: '#B0DFC5',
        lightGray: '#BDBDBD',
        blueSecundary: '#3B7CB2',
        darkGray: '#4F4F4F',
      },
    },
  },
});
