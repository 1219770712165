
import {
  Vue, Component, Prop, Watch, Emit,
} from 'vue-property-decorator';
import ModalHeader from './modal-header.component.vue';
import ModalOptions from './modal-options.entity';

@Component({
  components: {
    'modal-header': ModalHeader,
  },
})
export default class ModalComponent extends Vue {
  @Prop({
    required: true,
  })
  private value!: boolean;

  @Prop({
    required: true,
  })
  private options!: ModalOptions;

  @Prop() keyValue!: number;

  @Prop() scrollable!: boolean;

  @Emit('ok')
  private ok(): void {}

  @Emit('cancel')
  private cancel(): void {}

  private checkESC(evt: KeyboardEvent) {
    if (evt.key === 'Esc') {
      this.$emit('cancel');
    }
  }

  private get optionsHandled(): ModalOptions {
    const showClose = (this.options.showClose === undefined) || (this.options.showClose === null)
      ? true : this.options.showClose;

    return {
      title: this.options.title || '',
      titleSize: this.options.titleSize || '20px',
      titleAlign: this.options.titleAlign || 'left',
      showClose,
      description: this.options.description || '',
      showOk: this.options.showOk || false,
      disabledOk: this.options.disabledOk || false,
      labelOk: this.options.labelOk || 'Ok',
      okColor: this.options.okColor || 'primary',
      showCancel: this.options.showCancel || false,
      labelCancel: this.options.labelCancel || 'Cancelar',
      width: this.options.width || 500,
      persistent: this.options.persistent || false,
    };
  }
}
