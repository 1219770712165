export default class ResultMap {
  oldValue: string;

  newValue: string;

  constructor(obj: any) {
    this.oldValue = obj.oldValue;
    this.newValue = obj.newValue;
  }
}
