import Rule from './rule.entity';
import WorkItemDefaultVSTS from './default-work-item-vsts-core.json';
import WorkItemDefaultCORE from './default-work-item-core-task.json';
import AggregatorDefaultVSTS from './default-aggregator-vsts-core.json';
import AggregatorDefaultCORE from './default-aggregator-core-task.json';

export default class Configuration {
  integration: string;

  coreProjectName: string;

  originProject: string;

  destinationProject: string;

  ownerProjectEmail: string;

  rules: Rule[];

  constructor(obj: any) {
    this.integration = obj.integration;
    this.coreProjectName = obj.coreProjectName;
    this.originProject = obj.originProject;
    this.destinationProject = obj.destinationProject;
    this.ownerProjectEmail = obj.ownerProjectEmail;
    this.rules = (obj.rules || []).map((value: any) => new Rule(value));
  }

  newRule() {
    const rule = new Rule({});
    rule.newFilter();
    rule.newResult();
    this.rules.push(rule);
  }

  newDefaultWIRule() {
    const file = this.isVstsCoreIntegration()
      ? WorkItemDefaultVSTS
      : WorkItemDefaultCORE;
    this.addNewDefaultRule(file);
  }

  newDefaultAggRule() {
    const file = this.isVstsCoreIntegration()
      ? AggregatorDefaultVSTS
      : AggregatorDefaultCORE;
    this.addNewDefaultRule(file);
  }

  addNewDefaultRule(obj: any) {
    const rule = new Rule(obj);
    this.rules.push(rule);
  }

  copy(rule: Rule) {
    const copied = new Rule(rule);
    copied.name = `CÓPIA DE: ${copied.name}`;
    this.rules.push(copied);
  }

  isVstsCoreIntegration() {
    return this.integration === 'Integração de Workitem VSTS x CORE';
  }
}
