export default class Filter {
  source: string;

  fieldName: string;

  value: string;

  operation: string;

  constructor(obj: any) {
    this.source = obj.source;
    this.fieldName = obj.fieldName;
    this.value = obj.value;
    this.operation = obj.operation;
  }
}
