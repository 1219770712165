import { render, staticRenderFns } from "./configuration-edit.view.vue?vue&type=template&id=468da9d5&scoped=true&"
import script from "./configuration-edit.view.vue?vue&type=script&lang=ts&"
export * from "./configuration-edit.view.vue?vue&type=script&lang=ts&"
import style0 from "./configuration-edit.view.vue?vue&type=style&index=0&id=468da9d5&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "468da9d5",
  null
  
)

export default component.exports