import Filter from './filter.entity';
import Result from './result.entity';

export default class Rule {
  integrationApi: string;

  name: string;

  suffixSystemKey: string;

  filters: Filter[];

  results: Result[];

  workitemModel: string;

  constructor(obj: any) {
    this.integrationApi = obj.integrationApi;
    this.name = obj.name;
    this.suffixSystemKey = obj.suffixSystemKey;
    this.filters = (obj.filters || []).map((value: any) => new Filter(value));
    this.results = (obj.results || []).map((value: any) => new Result(value));
    this.workitemModel = obj.workitemModel;
  }

  newFilter() {
    this.filters.push(new Filter({}));
  }

  newResult() {
    this.results.push(new Result({}));
  }

  getFilterValue(fieldName: string, source: string = 'SELF'): string {
    const filter = this.filters.filter((el) => el.fieldName === fieldName
      && el.source === source)[0];
    if (filter) {
      return filter.value
    }
    return '';
  }

  getResultValue(fieldName: string): string {
    const result = this.results.filter((el) => el.destinationField === fieldName)[0];
    if (result) {
      return result.defaultValue;
    }
    return '';
  }

  inputResultValue(value: string, fieldName: string) {
    const result = this.results.filter((el) => el.destinationField === fieldName)[0];
    if (result) {
      result.defaultValue = value
    }
  }

  inputFilterValue(value: string, fieldName: string, source: string = 'SELF') {
    const filter = this.filters.filter((el) => el.fieldName === fieldName
      && el.source === source)[0];
    if (filter) {
      filter.value = value
    }
  }

  buildName(integrationType: string, workItemModel: string, projectName: string) {
    if (integrationType === 'Integração de Workitem VSTS x CORE' && workItemModel === 'Aggregator') {
      this.name = `Crud ${this.getResultValue('WorkItemType')} ${projectName}`;
    } else if (integrationType === 'Integração de Workitem VSTS x CORE' && workItemModel !== 'Aggregator') {
      this.name = `Crud ${this.getResultValue('WorkItemType')}
  ${this.getFilterValue('WorkItemType', 'PARENT')} ${projectName}`;
    } else if (integrationType !== 'Integração de Workitem VSTS x CORE' && workItemModel !== 'Aggregator') {
      this.name = `Crud Ordem De Serviço ${this.getFilterValue('WorkItemType')}
  ${this.getFilterValue('WorkItemTypeParent')} ${projectName}`;
    } else {
      this.name = `Crud Entregavel ${this.getFilterValue('WorkItemType')} ${projectName}`;
    }
  }
}
