import ProjectConfigurationIdName from './projects-configuration-id-name.entity';

export default class ProjectConfiguration {
  name: string;

  configurations: ProjectConfigurationIdName[];

  constructor(obj: any) {
    this.name = obj.name;
    this.configurations = (obj.configurations || [])
      .map((value: any) => new ProjectConfigurationIdName(value));
  }
}
