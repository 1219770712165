
import { Vue, Component } from 'vue-property-decorator';
import ConfigurationService from './domain/configuration.service';
import Configuration from './domain/configuration.entity';
import AuthenticationService from './domain/security/authentication.service';


@Component
export default class App extends Vue {
  created() {
    // @ts-ignore
    AuthenticationService.msal = this.$msal;
    if (!this.isAuthenticated) {
      AuthenticationService.login();
    }
  }

  get isAuthenticated() {
    return AuthenticationService.isAuthenticated();
  }
}
