import Vue from 'vue';
import VueRouter from 'vue-router';
import ConfigurationEdit from '../views/configuration-edit.view.vue';
import Home from '../views/home.view.vue';


Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/configuration/:id',
      name: 'configuration',
      component: ConfigurationEdit,
    },
    {
      path: '/configuration/new/:name',
      name: 'configuration',
      component: ConfigurationEdit,
    },
  ],
});

export default router;
