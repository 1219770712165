
import { Vue, Component, Prop } from 'vue-property-decorator';

@Component
export default class LabelSlot extends Vue {
  @Prop() label!: string;

  @Prop() table!: boolean;

  @Prop() customStyle?: string;
}
