
import {
  Vue, Component, Prop,
} from 'vue-property-decorator';

@Component
export default class ModalHeaderComponent extends Vue {
  @Prop() title!: string;

  @Prop() titleSize?: string;

  @Prop() titleAlign!: 'left' | 'center';

  @Prop() showClose!: boolean;

  close() {
    this.$emit('close');
  }
}
