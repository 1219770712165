
import Vue from 'vue'
import {
  Component, Prop, Emit, Watch,
} from 'vue-property-decorator';
import ModalComponent from '@/shared/modal.component.vue';
import ModalOptions from '@/shared/modal-options.entity';
import Configuration from '@/domain/configuration.entity';
import LabelSlot from '@/shared/label-slot.component.vue';
import Rule from '@/domain/rule.entity';
import WorkItemDefaultVSTS from '../domain/default-work-item-vsts-core.json';
import WorkItemDefaultCORE from '../domain/default-work-item-core-task.json';
import AggregatorDefaultVSTS from '../domain/default-aggregator-vsts-core.json';
import AggregatorDefaultCORE from '../domain/default-aggregator-core-task.json';
import RuleDatatable from './rules-datatable.component.vue';

@Component({
  components: {
    ModalComponent,
    LabelSlot,
    RuleDatatable,
  },
})
export default class ConfigurationModal extends Vue {
  @Prop({ required: true })
  show!: boolean;

  @Prop({ required: true })
  configuration!: Configuration;

  rule: Rule = new Rule({});

  newRules: Rule[] = [];

  get options(): ModalOptions {
    return {
      title: 'Adicionar novos Workitems',
      titleAlign: 'center',
      showClose: true,
      showOk: true,
      labelOk: 'Salvar',
      okColor: 'bluePrimary',
      showCancel: true,
      labelCancel: 'Cancelar',
      width: 1400,
      persistent: true,
    }
  }

  @Emit('ok')
  private ok(): any {}

  @Emit('cancel')
  private cancel(): void {
    this.resetModal();
  }

  workitemTypes = ['Aggregator', 'WorkItem']

  workItemModel: string = '';

  modalKey = 0;

  isAggregatorAndVstsToCore() {
    return this.workItemModel === 'Aggregator' && this.configuration.isVstsCoreIntegration();
  }

  selectWorkItemType() {
    if (this.workItemModel === 'Aggregator') {
      return this.newDefaultAggRule();
    }
    return this.newDefaultWIRule();
  }

  newDefaultWIRule() {
    const file = this.configuration.isVstsCoreIntegration()
      ? WorkItemDefaultVSTS
      : WorkItemDefaultCORE;
    this.rule = new Rule(file);
  }

  buildName() {
    this.rule.buildName(this.configuration.integration,
      this.workItemModel, this.configuration.coreProjectName)
  }

  newDefaultAggRule() {
    const file = this.configuration.isVstsCoreIntegration()
      ? AggregatorDefaultVSTS
      : AggregatorDefaultCORE;
    this.rule = new Rule(file);
  }

  addNewRule() {
    this.newRules.push(new Rule(this.rule));
  }

  @Watch('configuration.rules')
  resetModal() {
    this.newRules = [];
    this.rule = new Rule({});
    this.workItemModel = '';
  }

  saveNewRules() {
    this.$emit('saveNewRules', this.newRules)
  }
}
