
import { Vue, Component } from 'vue-property-decorator';
import ProjectConfiguration from '../domain/projects-configuration.entity';
import ProjectService from '../domain/project.service';
import Configuration from '../domain/configuration.entity';

@Component
export default class Home extends Vue {
  search = '';

  projects: ProjectConfiguration[] = [];

  created() {
    ProjectService
      .getProjectsConfiguration()
      .then((response) => {
        this.projects = response;
        return response;
      });
  }

  get isHomolog() {
    return process.env.VUE_APP_HOST_URL_BASE.includes('homolog') || process.env.VUE_APP_HOST_URL_BASE.includes('localhost');
  }

  get projectsFiltered() {
    if (this.search && this.search.length > 0) {
      return this
        .projects
        .filter((project) => project.name.toLowerCase().includes(this.search.toLowerCase()));
    }
    return this.projects;
  }
}
