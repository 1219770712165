import Vue from 'vue';
import axios from 'axios';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import msal from 'vue-msal';
import AuthenticationService from './domain/security/authentication.service';

Vue.use(msal, {
  auth: {
    tenantId: process.env.VUE_APP_TENANT_ID,
    clientId: process.env.VUE_APP_CLIENT_ID,
    redirectUri: process.env.VUE_APP_HOST_REDIRECT_URL,
    autoRefreshToken: true,
  },
});

axios.interceptors.request.use(
  async (config: any) => {
    const token: any = await AuthenticationService.token();
    const newConfig: any = {};
    Object.assign(newConfig, config);
    newConfig.headers.Authorization = `Bearer ${token.accessToken}`;
    return newConfig;
  },
);


Vue.config.productionTip = false;


axios.defaults.baseURL = process.env.VUE_APP_HOST_URL_BASE;
axios.defaults.withCredentials = true;

new Vue({
  vuetify,
  router,
  render: (h: any) => h(App),
}).$mount('#app');
