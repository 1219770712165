import axios from 'axios';
import IntegrationSummary from './integration-summary.entity';
import IntegrationApiSummary from './integration-api-summary.entity';

export default class IntegrationService {
  static async getIntegrations(): Promise<IntegrationSummary[]> {
    return axios
      .get('/mapper/integration')
      .then((response: any) => response.data.map((value: any) => new IntegrationSummary(value)));
  }

  static async getIntegrationsApiByIntegration(name: string): Promise<IntegrationApiSummary[]> {
    return axios
      .get(`/mapper/integration/integration-api/${name}`)
      .then((response: any) => response.data.map((value: any) => new IntegrationApiSummary(value)));
  }
}
