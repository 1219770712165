export default class UserSecurity {
  name: string

  email: string

  username: string

  constructor(obj: any) {
    this.name = obj.name || '';
    this.email = obj.userName || '';
    this.username = this.email.indexOf('@') > 0 ? this.email.split('@')[0].trim() : '';
  }
}
