import axios from 'axios';
import Configuration from './configuration.entity';

export default class ConfigurationService {
  public static async get(id: string): Promise<Configuration> {
    return axios
      .get(`/mapper/configuration/${id}`)
      .then((response) => new Configuration(response.data));
  }

  public static async save(configuration: Configuration) {
    return axios
      .post('/mapper/configuration', configuration)
      .then((response) => new Configuration(response.data));
  }
}
