
import { Vue, Component } from 'vue-property-decorator';
import ConfigurationService from '../domain/configuration.service';
import Configuration from '../domain/configuration.entity';
import Rule from '../domain/rule.entity';
import Result from '../domain/result.entity';
import IntegrationSummary from '../domain/integration-summary.entity';
import IntegrationApiSummary from '../domain/integration-api-summary.entity';
import IntegrationService from '../domain/integration.service';
import ConfigurationModal from './configuration-modal.component.vue';
import { processErrorMessage } from '../exceptions/error.helper';

@Component({ components: { ConfigurationModal } })
export default class ConfigurationEdit extends Vue {
  configuration: Configuration = new Configuration({});

  alert = false;

  showProgress = false;

  message = '';

  resultCopied = new Result({});

  sources = ['PARENT', 'SELF'];

  operations = ['DATE_MORE_THAN', 'EQUALS', 'IN'];

  fieldsType = ['FIELD', 'FIXED', 'REPLACE', 'MAP'];

  handlers = ['', 'CALCULATE_DATE', 'DATE', 'REPLACE_HTML', 'USER_EMAIL', 'USER_TASK'];

  integrations: IntegrationSummary[] = [];

  integrationsApi: IntegrationApiSummary[] = [];

  showModal = false;

  created() {
    this.initInterations();
    this.initPage();
  }

  initInterations() {
    IntegrationService
      .getIntegrations()
      .then((response: IntegrationSummary[]) => {
        this.integrations = response;
        return response;
      });
  }

  initIntegrationsApi() {
    IntegrationService
      .getIntegrationsApiByIntegration(this.configuration.integration)
      .then((response: IntegrationApiSummary[]) => {
        this.integrationsApi = response;
        return response;
      });
  }

  initPage() {
    this.configuration = new Configuration({});
    if (this.$route.params && this.$route.params.id) {
      this.showProgress = true;
      ConfigurationService.get(this.$route.params.id)
        .then((response) => {
          this.configuration = response;
          this.initIntegrationsApi();
          return response;
        })
        .finally(() => {
          this.showProgress = false;
        });
    } else if (this.$route.params && this.$route.params.name) {
      this.configuration.coreProjectName = this.$route.params.name;
    }
  }

  newRule() {
    this.configuration.newRule();
  }

  newDefaultRule() {
    this.configuration.newDefaultWIRule();
  }

  newDefaultAggregator() {
    this.configuration.newDefaultAggRule();
  }

  duplicateRule(rule: Rule) {
    this.configuration.copy(rule);
  }

  save() {
    this.showProgress = true;
    ConfigurationService
      .save(this.configuration)
      .then(() => this.$router.push('/'))
      .catch((err) => {
        this.alert = true;
        this.message = processErrorMessage(err);
      })
      .finally(() => {
        this.showProgress = false;
      });
  }

  removeRule(index: number) {
    this.configuration.rules.splice(index, 1);
  }

  newFilter(index: number) {
    this.configuration.rules[index].newFilter();
  }

  newResult(index: number) {
    this.configuration.rules[index].newResult();
  }

  removeFilter(ruleIndex: number, filterIndex: number) {
    this.configuration.rules[ruleIndex].filters.splice(filterIndex, 1);
  }

  removeResult(ruleIndex: number, resultIndex: number) {
    this.configuration.rules[ruleIndex].results.splice(resultIndex, 1);
  }

  copyResult(result: Result) {
    this.resultCopied = new Result(result);
  }

  pastResult(ruleIndex: number) {
    this.configuration.rules[ruleIndex].results.push(this.resultCopied);
  }

  cleanMaps(ruleIndex: number, resultIndex: number) {
    this.configuration.rules[ruleIndex].results[ruleIndex].resultMaps = [];
  }

  newResultMap(ruleIndex: number, resultIndex: number) {
    this.configuration.rules[ruleIndex].results[resultIndex].newMaps();
  }

  removeResultMap(ruleIndex: number, resultIndex: number, mapIndex: number) {
    this.configuration.rules[ruleIndex].results[resultIndex].resultMaps.splice(mapIndex, 1);
  }

  saveNewRules(rules: Rule[]) {
    this.showModal = false
    rules.forEach((el) => {
      this.configuration.rules.push(new Rule(el))
    })
  }
}
