
import Rule from '@/domain/rule.entity';
import { Vue, Component, Prop } from 'vue-property-decorator';
import Header from './header.json';

@Component({
  name: 'ContractDataTable',
  components: {},
})

export default class RulesDataTable extends Vue {
  @Prop({}) rules!: Rule[];

  header = Header;
}

