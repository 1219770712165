import ResultMap from './result-map.entity';

export default class Result {
  type: string;

  source: string;

  originField: string;

  destinationField: string;

  defaultValue: string;

  handler: string;

  resultMaps: ResultMap[];

  constructor(obj: any) {
    this.type = obj.type;
    this.source = obj.source;
    this.originField = obj.originField;
    this.destinationField = obj.destinationField;
    this.defaultValue = obj.defaultValue;
    this.handler = obj.handler;
    this.resultMaps = (obj.resultMaps || []).map((value: any) => new ResultMap(value));
  }

  newMaps() {
    this.resultMaps.push(new ResultMap({}));
  }
}
