import axios from 'axios';
import ProjectConfiguration from './projects-configuration.entity';

export default class ProjectService {
  public static async getProjectsConfiguration(): Promise<ProjectConfiguration[]> {
    return axios
      .get('/mapper/configuration/grouped-project')
      .then((response: any) => response.data.map((value: any) => new ProjectConfiguration(value)));
  }
}
